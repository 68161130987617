import React, { useState, useEffect, useContext, createContext } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { ability, defineRulesFor } from '../casl/ability';

firebase.initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
});

const GET_USER = gql`
  query GetSignedInUser($firebase_uid: String) {
    user(firebase_uid: $firebase_uid) {
      id
      name
      code
      location
      email
      role
      organization {
        id
        name
        code
      }
      subordinates {
        id
        name
      }
    }
  }
`;

export const Context = createContext();

export const Provider = ({ children }) => {
  const apolloClient = useApolloClient();
  const [authUser, setAuthUser] = useState(null);

  // Redundant user state used in TaskUpload in order to enable access to token
  const [firebaseUser, setFirebaseUser] = useState(null);
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const clearState = () => {
    setError(null);
    setRedirectToReferrer(false);
    setAuthUser(null);
  };

  const doSignInWithEmailAndPassword = async (email, password) => {
    try {
      setLoading(true);
      await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const doSignOut = async () => {
    await firebase.auth().signOut();
    clearState();
  };

  const doPasswordReset = async (email) => {
    return await firebase.auth().sendPasswordResetEmail(email);
  };

  useEffect(() => {
    const mergeDbAndFbUser = async () => {
      const { data, error } = await apolloClient.query({
        query: GET_USER,
        variables: { firebase_uid: authUser.uid },
        fetchPolicy: 'network-only',
      });
      if (error) setError(error);

      if (data && data.user) {
        setAuthUser((fbUser) => {
          ability.update(defineRulesFor({ ...data.user, ...fbUser }));
          return { ...data.user, ...fbUser };
        });
      }
    };

    if (authUser && !authUser.organization) {
      mergeDbAndFbUser();
    }
  }, [authUser, setAuthUser, apolloClient]);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (fbUser) => {
      if (fbUser) {
        const user = await fbUser;

        setFirebaseUser(user);
        setAuthUser(user);

        setRedirectToReferrer(true);
      } else {
        clearState();
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const isSupervisor =
    authUser &&
    ((authUser.subordinates && authUser.subordinates.length > 0) ||
      authUser.role === 'admin');

  // Make the context object:
  const authContext = {
    authUser,
    redirectToReferrer,
    loading,
    error,
    doSignInWithEmailAndPassword,
    doSignOut,
    doPasswordReset,
    setError,
    isSupervisor,
    firebaseUser,
    setFirebaseUser,
  };

  // pass the value in provider and return
  return <Context.Provider value={authContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

export const useAuthContext = () => useContext(Context);
