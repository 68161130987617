import React from 'react';
import {
  useCustomerContext,
  Provider as CustomerProvider,
} from './customerContext';
import {
  useProductContext,
  Provider as ProductProvider,
} from './productContext';
import { useAuthContext, Provider as AuthProvider } from './authContext';
import { useTaskContext, Provider as TaskProvider } from './taskContext';
import { useCartContext, Provider as CartProvider } from './cartContext';
import { useOrdersContext, Provider as OrdersProvider } from './ordersContext';
import { useFilterContext, Provider as FilterProvider } from './filterContext';
import {
  useUserFormContext,
  Provider as UserFormProvider,
} from './userFormContext';
import {
  useIndexdbContext,
  Provider as IndexdbProvider,
} from './indexdbContext';
import {
  useAbilityContext,
  Provider as AbilityProvider,
} from './abilityContext';

const ContextProvider = ({ children }) => {
  return (
    <AuthProvider>
      <CustomerProvider>
        <ProductProvider>
          <OrdersProvider>
            <FilterProvider>
              <UserFormProvider>
                <TaskProvider>
                  <CartProvider>
                    <IndexdbProvider>
                      <AbilityProvider>{children}</AbilityProvider>
                    </IndexdbProvider>
                  </CartProvider>
                </TaskProvider>
              </UserFormProvider>
            </FilterProvider>
          </OrdersProvider>
        </ProductProvider>
      </CustomerProvider>
    </AuthProvider>
  );
};

export {
  useIndexdbContext,
  useTaskContext,
  useOrdersContext,
  useProductContext,
  useCartContext,
  useCustomerContext,
  useAuthContext,
  useFilterContext,
  useUserFormContext,
  useAbilityContext,
  ContextProvider,
};
