import React, { createContext, useContext, useState } from 'react';
import moment from 'moment';
import { computePrice } from '@msales/shared/utils/price';
export const Context = createContext({});

export const Provider = (props) => {
  // Initial values are obtained from the props
  const { children } = props;

  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment());
  const [filter, setFilter] = useState(0);
  const [orderStatus, setOrderStatus] = useState(2);
  const [customerCode, setCustomerCode] = useState(null);

  const getOrderQuantity = (order) => {
    const sum = (a, b) => a + b;
    const OrderQuantity = order.items.map((o) => o.quantity);
    const quantity = OrderQuantity.reduce(sum);

    return quantity;
  };

  const getOrderTotal = (order) => {
    const { items } = order;
    const total = items.reduce((a, b) => a + b.price * b.quantity, 0);

    return total.toFixed(2);
  };

  const getDiscountedTotal = (order) => {
    const { items } = order;
    const total = items.reduce(
      (a, b) =>
        a + computePrice(b.price, b.discount1, b.discount2) * b.quantity,
      0,
    );
    return total.toFixed(2);
  };

  // Make the context object:
  const ordersContex = {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    getOrderQuantity,
    getOrderTotal,
    getDiscountedTotal,
    filter,
    setFilter,
    orderStatus,
    setOrderStatus,
    customerCode,
    setCustomerCode,
  };

  // pass the value in provider and return
  return <Context.Provider value={ordersContex}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

export const useOrdersContext = () => useContext(Context);
