import React from 'react';
import { Badge } from 'antd';
import { useCartContext } from '../../context';

const CartBadge = ({ children }) => {
  const { cart } = useCartContext();
  return (
    <Badge style={{ marginTop: '6px' }} count={cart && cart.length}>
      {children}
    </Badge>
  );
};

export default CartBadge;
