import React, { createContext, useContext, useState } from 'react';
import useLocalStorage from '@msales/shared/hooks/useLocalStorage';
import { useApolloClient } from '@apollo/react-hooks';
import moment from 'moment';
import Dexie from 'dexie';
import gql from 'graphql-tag';
import orderTypeFragment from '@msales/shared/graphql/fragments/orderType/orderTypeInfo';
import customerLimitedInfo from '@msales/shared/graphql/fragments/customer/customerLimitedInfo';
import productFragment from '@msales/shared/graphql/fragments/product/productInfo';

export const Context = createContext({});
const db = new Dexie('MPOS');
db.version(2).stores({
  table_changes: '++id, table, timestamp',
  customers:
    '++, id, name, code, address, totalDebt, dueDebt, blocked, blockWarning, city, category, __typename',
  products:
    '++, id, barcode, code, color, colorDescription, decimal, discount1, discount2, measurementUnit, name, price, quantityUnit, retailPrice, stockQuantity, weight, wholesalePrice, brand, category, division, __typename',
});

const GET_DATA_FOR_DATABASE = gql`
  query {
    orderTypes {
      ...orderTypeInfo
    }
    discountClasses {
      ...orderTypeInfo
    }
    giftClasses {
      ...orderTypeInfo
    }
    customersLimitedFields {
      nodes {
        ...customerLimitedInfo
      }
      createdTimestamp
    }
    productsAll {
      nodes {
        ...productInfo
        brand {
          id
          name
        }
        category {
          id
          name
        }
      }
      createdTimestamp
    }
  }
  ${orderTypeFragment}
  ${customerLimitedInfo}
  ${productFragment}
`;

export const Provider = (props) => {
  // Initial values are obtained from the props
  const { children } = props;
  const apolloClient = useApolloClient();

  const [orderTypesCached, setOrderTypesCached] = useLocalStorage(
    'orderTypes',
    null,
  );

  const checkDataLoad = async () => {
    const [customerTimestamp, productsTimestamp] = await Promise.all([
      checkTimestamps('customers'),
      checkTimestamps('products'),
    ]);

    if (
      !customerTimestamp ||
      !productsTimestamp ||
      moment().diff(moment(customerTimestamp), 'minutes') > 30 ||
      moment().diff(moment(productsTimestamp), 'minutes') > 30
    ) {
      const result = await onDemandDatabaseDataLoad();
      return result;
    }
    return true;
  };

  const delay = () =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve('');
      }, 1000);
    });

  const checkTimestamps = async (entityName) => {
    await db.open();
    const { timestamp } =
      (await db
        .table('table_changes')
        .where('table')
        .equals(entityName)
        .first()) || {};
    return timestamp;
  };

  const createEntityTimestamp = async (entityName, timestamp) => {
    await db.open();
    return await db
      .table('table_changes')
      .add({ table: entityName, timestamp: Number(timestamp) });
  };

  const updateTimestamp = async (entityName, timestamp) => {
    await db.open();
    await db
      .table('table_changes')
      .where('table')
      .equals(entityName)
      .modify({ timestamp: Number(timestamp) });
  };

  const triggerIndexdbDataLoad = async (data, entityName) => {
    await db.open();
    await db.table(entityName).clear();
    await delay();
    await db.table(entityName).bulkAdd(data);
  };

  const getEntityDatabaseRows = async (entityName) => {
    return await db.table(entityName).toArray();
  };

  const onDemandDatabaseDataLoad = async () => {
    const [customerExists, productsExist] = await Promise.all([
      checkTimestamps('customers'),
      checkTimestamps('products'),
    ]);
    const { data, error } = await apolloClient.query({
      query: GET_DATA_FOR_DATABASE,
      fetchPolicy: 'network-only',
    });
    const {
      orderTypes,
      discountClasses,
      giftClasses,
      productsAll,
      customersLimitedFields,
    } = data;
    setOrderTypesCached({ orderTypes, discountClasses, giftClasses });
    if (
      customersLimitedFields?.nodes?.length &&
      customersLimitedFields?.createdTimestamp
    ) {
      await triggerIndexdbDataLoad(customersLimitedFields?.nodes, 'customers');
      (await customerExists)
        ? updateTimestamp('customers', customersLimitedFields?.createdTimestamp)
        : createEntityTimestamp(
            'customers',
            customersLimitedFields?.createdTimestamp,
          );
      console.log('customers');
    }
    if (productsAll?.nodes?.length && productsAll?.createdTimestamp) {
      await triggerIndexdbDataLoad(productsAll?.nodes, 'products');
      (await productsExist)
        ? updateTimestamp('products', productsAll?.createdTimestamp)
        : createEntityTimestamp('products', productsAll?.createdTimestamp);
      console.log('products');
    }
    return true;
  };

  const generalInfoAboutData = async () => {
    const { timestamp } =
      (await db.table('table_changes').orderBy('timestamp').first()) || {};
    const totalOrderTypes = orderTypesCached?.orderTypes?.length || 0;
    const totalCustomers = await db.table('customers').count();
    const totalProducts = await db.table('products').count();
    return {
      dateOfLastUpdate: timestamp
        ? moment(new Date(timestamp)).format('DD/MM/YYYY HH:mm:ss')
        : '',
      totalOrderTypes,
      totalCustomers,
      totalProducts,
      needSync: !totalOrderTypes || !totalCustomers || !totalProducts,
    };
  };

  // Make the context object:
  const indexdbContext = {
    checkTimestamps,
    triggerIndexdbDataLoad,
    getEntityDatabaseRows,
    orderTypesCached,
    setOrderTypesCached,
    onDemandDatabaseDataLoad,
    generalInfoAboutData,
    checkDataLoad,
  };

  // pass the value in provider and return
  return <Context.Provider value={indexdbContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

export const useIndexdbContext = () => useContext(Context);
