import React, { createContext, useContext, useState, useRef } from 'react';
export const Context = createContext({});

export const Provider = (props) => {
  // Initial values are obtained from the props
  const { children } = props;

  // Use State to keep the values
  const [searchValue, setSearchValue] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showFavourites, setShowFavourites] = useState(false);
  const [customerCategory, setCustomerCategory] = useState(true);
  const [showDiscounted, setShowDiscounted] = useState(false);
  const [showColored, setShowColored] = useState(false);
  const [orderByFrequency, setOrderByFrequency] = useState(true);
  const [resultCount, setResultCount] = useState(0);

  const [loading, setLoading] = useState(false);
  const listRef = useRef(null);

  const isFiltered =
    selectedBrand ||
    selectedCategory ||
    showFavourites ||
    showDiscounted ||
    customerCategory ||
    showColored;

  const clearFilters = () => {
    setSelectedBrand(null);
    setSelectedCategory(null);
    setShowFavourites(false);
    setShowDiscounted(false);
    setShowColored(false);
    setCustomerCategory(false);
  };

  // Make the context object:
  const productContext = {
    searchValue,
    setSearchValue,
    selectedBrand,
    setSelectedBrand,
    selectedCategory,
    setSelectedCategory,
    listRef,
    showFavourites,
    setShowFavourites,
    showDiscounted,
    setShowDiscounted,
    showColored,
    setShowColored,
    resultCount,
    setResultCount,
    loading,
    setLoading,
    isFiltered,
    clearFilters,
    orderByFrequency,
    setOrderByFrequency,
    customerCategory,
    setCustomerCategory,
  };

  // pass the value in provider and return
  return <Context.Provider value={productContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

export const useProductContext = () => useContext(Context);
