import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';

const NavItem = ({ to, label, location, children }) => {
  const selected = location.pathname.startsWith(to);
  return (
    <StyledNavLink selected={selected} href={to}>
      {children}
      <StyledLabel>{label}</StyledLabel>
    </StyledNavLink>
  );
};

const StyledNavLink = styled.a`
  color: ${(props) => (props.selected ? '#1890ff' : 'hsla(0, 0%, 6.7%, 0.6)')};
`;

const StyledLabel = styled.div`
  padding: 0 4px;
  text-overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  font-size: 0.7rem;
`;

export default withRouter(NavItem);
