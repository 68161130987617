import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Spinner from './components/Spinner';
import PrivateRoute from './components/PrivateRoute';
import SignInPage from './components/SignIn';

const Product = lazy(() => import('./views/Products'));
const Customer = lazy(() => import('./views/Customers'));
const Cart = lazy(() => import('./components/Cart'));
const Order = lazy(() => import('./components/Orders'));
const PendingOrder = lazy(() => import('./views/PendingOrder'));
const Account = lazy(() => import('./components/Account'));
const Admin = lazy(() => import('./views/Admin'));
const Reports = lazy(() => import('./views/Reports'));
const Tasks = lazy(() => import('./views/Task'));
const Task = lazy(() => import('./views/Task/Task'));
const CreateTaskForm = lazy(() => import('./views/Task/CreateTaskForm'));
const OfflineMode = lazy(() => import('./views/OfflineMode'));
const OcrPreview = lazy(() => import('./views/OcrPreview'));
const SalesmanMap = lazy(() => import('./views/Reports/SalesmanMapReport'));
const SalesmanReport = lazy(() => import('./views/Reports/SalesmanReport'));

const LazyComponent = ({ component: Component, props }) => {
  return (
    <Suspense fallback={<Spinner />}>
      <Component {...props} />
    </Suspense>
  );
};

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/sign-in" component={SignInPage} />
      <Route exact path="/index.html" component={SignInPage} />
      <PrivateRoute
        exact
        path="/customers"
        component={() => <LazyComponent component={Customer} />}
      />
      <PrivateRoute
        exact
        path="/products"
        component={() => <LazyComponent component={Product} />}
      />
      <Route
        exact
        path="/cart"
        component={() => <LazyComponent component={Cart} />}
      />
      <PrivateRoute
        exact
        path="/reports/salesmans/report"
        component={() => <LazyComponent component={SalesmanReport} />}
      />
      <PrivateRoute
        exact
        path="/reports/salesmans/map"
        component={() => <LazyComponent component={SalesmanMap} />}
      />
      <PrivateRoute
        exact
        path="/reports/orders"
        component={() => <LazyComponent component={Order} />}
      />
      <PrivateRoute
        exact
        path="/pending-orders"
        component={() => <LazyComponent component={PendingOrder} />}
      />
      <PrivateRoute
        exact
        path="/account"
        component={() => <LazyComponent component={Account} />}
      />
      <PrivateRoute
        exact
        path="/admin"
        component={() => <LazyComponent component={Admin} />}
      />
      <PrivateRoute
        path="/reports"
        component={() => <LazyComponent component={Reports} />}
      />
      <Route
        exact
        path="/offlinemode"
        component={() => <LazyComponent component={OfflineMode} />}
      />
      <PrivateRoute
        exact
        path="/ocrPreview"
        component={() => <LazyComponent component={OcrPreview} />}
      />

      <Redirect from="*" to="/sign-in" />
    </Switch>
  );
};

export default Routes;
