import React, { useEffect } from 'react';
import { useIndexdbContext } from '../context';

const OfflineProvider = ({ children }) => {
  const { checkDataLoad } = useIndexdbContext();
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      checkDataLoad();
      let id = setInterval(async () => {
        checkDataLoad();
      }, 10000);
      return () => clearInterval(id);
    }
  }, []);

  return <>{children}</>;
};

export default OfflineProvider;
