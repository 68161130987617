import React, { createContext, useContext, useState } from 'react';
export const Context = createContext({});

export const Provider = (props) => {
  // Initial values are obtained from the props
  const { children } = props;

  const [selectedTask, setSelectedTask] = useState(null);

  // Use State to keep the values
  const [brand, setBrand] = useState(null);
  const [type, setType] = useState(null);
  const [priority, setPriority] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [products, setProducts] = useState([]);

  const reset = () => {
    setBrand(null);
    setCustomers([]);
    setProducts([]);
    setPriority(null);
  };
  // Make the context object:
  const customerContext = {
    brand,
    setBrand,
    customers,
    setCustomers,
    products,
    setProducts,
    reset,
    type,
    setType,
    priority,
    setPriority,
    selectedTask,
    setSelectedTask,
  };

  // pass the value in provider and return
  return (
    <Context.Provider value={customerContext}>{children}</Context.Provider>
  );
};

export const { Consumer } = Context;

export const useTaskContext = () => useContext(Context);
