import gql from 'graphql-tag';

export default gql`
  fragment productInfo on Product {
    id
    name
    barcode
    code
    stockQuantity
    measurementUnit
    wholesalePrice
    retailPrice
    price
    discount1
    discount2
    decimal
    quantityUnit
    weight
    color
    colorDescription
    division
  }
`;
