import gql from 'graphql-tag';

export default gql`
  fragment orderTypeInfo on OrderType {
    id
    name
    code
    initialStatus
  }
`;
