import React from 'react';
import { Badge } from 'antd';
import { useAuthContext } from '../../context';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import * as colors from '@ant-design/colors';

const getTaskStats = gql`
  query GetTaskStats($condition: TaskCondition) {
    tasks(condition: $condition) {
      nodes {
        id
        status {
          id
          name
        }
      }
      totalCount
    }
  }
`;

const TaskStatsBadge = ({ children }) => {
  const { authUser, isSupervisor } = useAuthContext();

  // If the user is admin get tasks they assigned to subordinates
  // otherwise get tasks assigned to the user
  const variables = isSupervisor
    ? { createdBy: authUser.id }
    : { assignee: authUser.id };

  const { data, error } = useQuery(getTaskStats, {
    variables: {
      condition: variables,
    },
    fetchPolicy: 'cache-network',
  });

  if (error) console.error(error.message);

  const newCount = data?.tasks?.nodes.filter(
    ({ status }) => status.name === 'Novi',
  ).length;
  const finishedCount = data?.tasks?.nodes.filter(
    ({ status }) => status.name === 'Urađen',
  ).length;
  const redoCount = data?.tasks?.nodes.filter(
    ({ status }) => status.name === 'Doraditi',
  ).length;
  console.log(newCount, finishedCount, redoCount);

  return (
    <Badge
      style={{ marginTop: '6px', background: colors.blue.primary }}
      count={newCount || 0}
    >
      <Badge
        offset={[24, 10]}
        style={{
          marginTop: '6px',
          background: colors.green.primary,
        }}
        count={finishedCount || 0}
      >
        <Badge
          offset={[15, 24]}
          style={{
            background: colors.volcano.primary,
          }}
          count={redoCount || 0}
        >
          {children}
        </Badge>
      </Badge>
    </Badge>
  );
};

export default TaskStatsBadge;
