import React, { createContext, useContext, useState } from 'react';
import useLocalStorage from '@msales/shared/hooks/useLocalStorage';
import { computePrice } from '@msales/shared/utils/price';

export const Context = createContext({});

export const Provider = ({ children }) => {
  const [cart, setCart] = useLocalStorage('cart', []);
  const [ocrPreviewItems, setOcrPreviewItems] = useLocalStorage(
    'ocrPreviewItems',
    [],
  );
  const [comment, setComment] = useState(null);
  const [requestedDeliveryDate, setRequestedDeliveryDate] = useState(null);
  const [delivery, setDelivery] = useState(true);
  const [deliveryLocation, setDeliveryLocation] = useState(null);
  const [discountClass, setDiscountClass] = useLocalStorage(
    'discounClass',
    null,
  );
  const [giftClass, setGiftClass] = useLocalStorage('giftClass', null);
  const [giftClasses, setGiftClasses] = useLocalStorage('giftClasses', null);
  const [retail, setRetail] = useState(false);
  const [orderType, setOrderType] = useLocalStorage('doc', null);
  const [page, setPage] = useState('1');
  const [result, setResult] = useState(null);

  const addProduct = (product, quantity, discount2) => {
    setCart((c) => {
      const cartProduct = { ...product, quantity };
      if (discount2) cartProduct.discount2 = discount2;
      return [cartProduct, ...c];
    });
  };

  const removeProduct = (product) => {
    setCart((c) => [...c.filter((p) => product.id !== p.id)]);
  };

  const editQuantity = (product, newQuantity, discount2) => {
    setOcrPreviewItems((c) => {
      const i = c.findIndex((p) => p.id === product.id);
      if(!!c[i])
        c[i].quantity = newQuantity;
      return [...c];
    });
    setCart((c) => {
      const i = c.findIndex((p) => p.id === product.id);
      c[i].quantity = newQuantity;
      if (discount2) c[i].discount2 = discount2;
      return [...c];
    });
  };

  const getProductQuantity = (product) => {
    const found = cart.find((p) => p.id === product.id);
    return found ? found.quantity : undefined;
  };

  const clearCart = () => {
    setCart([]);
    setOcrPreviewItems([]);
    setComment(null);
    setRequestedDeliveryDate(null);
    setDelivery(true);
    setDeliveryLocation(null);
    setRetail(false);
  };

  const inCart = (product) => {
    if (cart && cart.length > 0) {
      const found = cart.find(
        (productInCart) => product.id === productInCart.id,
      );
      return { ...found };
    }
    return null;
  };

  const cartTotal = () => {
    const total = cart.reduce((a, b) => a + b.wholesalePrice * b.quantity, 0);
    return total.toFixed(2);
  };

  const cartDiscountedTotal = () => {
    const total = cart.reduce(
      (a, b) =>
        a +
        computePrice(
          b.wholesalePrice,
          b.isHistoryBasedKO ? 0 : b.discount1,
          b.discount2,
        ) *
          b.quantity,
      0,
    );
    return total.toFixed(2);
  };

  const cartWeight = () => {
    const weight = cart.reduce((a, b) => a + b.weight * b.quantity, 0);
    return weight.toFixed(2);
  };

  const validateCart = () => {
    return cart && cart.every((item) => item.quantity && !isNaN(item.quantity));
  };

  const getPageTitle = () => {
    if (page === 0) return 'Pregled';
    else if (page === 1) return 'Zaključi';
    else if (page === 2) return 'Potvrda';
  };

  const addProductToCart = (product, quantity, koDiscount) => {
    const validQuantity = quantity && quantity > 0;
    let validKO =
      koDiscount &&
      koDiscount > 0 &&
      koDiscount <= 100 &&
      koDiscount !== product.discount2 &&
      orderType.code === 'KO';

    if (validQuantity && !product.quantity) {
      addProduct(product, quantity, validKO && koDiscount);
    } else if (validQuantity && (product.quantity !== quantity || validKO))
      editQuantity(product, quantity, validKO && koDiscount);
    else if (quantity === 0) removeProduct(product);
  };

  const cartContext = {
    page,
    setPage,
    cart,
    setCart,
    addProduct,
    removeProduct,
    editQuantity,
    clearCart,
    inCart,
    cartTotal,
    getProductQuantity,
    delivery,
    setDelivery,
    deliveryLocation,
    setDeliveryLocation,
    discountClass,
    setDiscountClass,
    retail,
    setRetail,
    validateCart,
    getPageTitle,
    cartWeight,
    result,
    setResult,
    comment,
    setComment,
    requestedDeliveryDate,
    setRequestedDeliveryDate,
    cartDiscountedTotal,
    orderType,
    setOrderType,
    addProductToCart,
    giftClasses,
    setGiftClasses,
    giftClass,
    setGiftClass,
    ocrPreviewItems,
    setOcrPreviewItems,
  };

  return <Context.Provider value={cartContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

export const useCartContext = () => useContext(Context);
