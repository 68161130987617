import React, { createContext, useContext, useState } from 'react';

export const Context = createContext({});

export const Provider = ({ children }) => {
  const [organization, setOrganization] = useState(null);
  const [users, setUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [showSubordinates, setShowSubordinates] = useState(false);
  const [superior, setSuperior] = useState(null);
  const [subordinates, setSubordinates] = useState([]);

  const userFormContext = {
    organization,
    setOrganization,
    users,
    setUsers,
    show,
    setShow,
    showSubordinates,
    setShowSubordinates,
    subordinates,
    setSubordinates,
    superior,
    setSuperior,
  };

  return (
    <Context.Provider value={userFormContext}>{children}</Context.Provider>
  );
};

export const { Consumer } = Context;

export const useUserFormContext = () => useContext(Context);
