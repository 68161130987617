import React from 'react';
import NavItem from './NavItem';
import ExternalNavItem from './ExternalNavItem';
import CartBadge from './CartBadge';
import { withRouter, useLocation } from 'react-router-dom';
import { Row, Col } from 'antd';
import { useAuthContext, useAbilityContext } from '../../context';
import {
  TeamOutlined,
  CodeSandboxOutlined,
  ShoppingCartOutlined,
  FileDoneOutlined,
  PaperClipOutlined,
  DisconnectOutlined,
} from '@ant-design/icons';
import TaskStatsBadge from './TaskStatsBadge';

const BottomNav = () => {
  const { authUser } = useAuthContext();
  const ability = useAbilityContext();
  const location = useLocation();
  if (location?.pathname.endsWith('sign-in')) return null;
  return (
    <div
      className="hide-element-on-keyboard"
      style={{
        background: '#fff',
        position: 'fixed',
        overflow: 'hidden',
        bottom: 0,
        zIndex: 2,
        width: '100%',
        lineHeight: 1.1,
        height: '40px',
        paddingTop: '4px',
        borderTop: '1px solid hsla(0,0%,53.3%,.4)',
      }}
    >
      <Row style={{ justifyContent: 'space-evenly' }}>
        {authUser && (
          <Col style={{ textAlign: 'center', marginLeft: '4px' }} span={4}>
            <NavItem to={'/customers'} label={'Kupci'}>
              <TeamOutlined style={{ fontSize: '20px' }} />
            </NavItem>
          </Col>
        )}
        {authUser && (
          <Col style={{ textAlign: 'center' }} span={5}>
            <NavItem to={'/products'} label={'Artikli'}>
              <CodeSandboxOutlined style={{ fontSize: '20px' }} />
            </NavItem>
          </Col>
        )}
        <Col style={{ textAlign: 'center' }} span={5}>
          <CartBadge>
            <NavItem to={'/cart'} label={'Korpa'}>
              <ShoppingCartOutlined style={{ fontSize: '20px' }} />
            </NavItem>
          </CartBadge>
        </Col>
        {authUser && ability.can('view', 'tasks') && (
          <Col style={{ textAlign: 'center' }} span={5}>
            <TaskStatsBadge>
              <ExternalNavItem
                to={'https://mpos.infostudio.app/zadaci'}
                label={'Zadaci'}
              >
                <FileDoneOutlined style={{ fontSize: '20px' }} />
              </ExternalNavItem>
            </TaskStatsBadge>
          </Col>
        )}
        {authUser && ability.can('view', 'reports') && (
          <Col style={{ textAlign: 'center' }} span={4}>
            <NavItem to={'/reports'} label={'Izvještaji'}>
              <PaperClipOutlined style={{ fontSize: '20px' }} />
            </NavItem>
          </Col>
        )}
        {!authUser && (
          <Col style={{ textAlign: 'center' }} span={4}>
            <NavItem to={'/offlinemode'} label={'Offline'}>
              <DisconnectOutlined style={{ fontSize: '20px' }} />
            </NavItem>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default withRouter(BottomNav);
