import React from 'react';
import ApolloClient from 'apollo-boost';
import firebase from 'firebase/app';
import { ApolloProvider } from '@apollo/react-hooks';

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_SERVER,
  fetchOptions: {
    credentials: 'include',
  },
  request: async (operation) => {
    try {
      const currentUser = firebase.auth().currentUser;
      let token = '';
      if (currentUser) {
        token = await currentUser.getIdToken(true);
      }
      const documentStr = localStorage.getItem('doc');
      const documentJSON = documentStr && JSON.parse(documentStr);
      const document = documentJSON && documentJSON.code;

      operation.setContext({
        headers: {
          authorization: token,
          document,
        },
      });
    } catch (error) {
      postToSlack(error.message);
    }
  },
  // onError: ({ networkError, graphQLErrors }) => {
  //   if (graphQLErrors) {
  //     console.log(graphQLErrors);
  //     graphQLErrors.forEach(({ message, locations, path }) => {
  //       const errorMessage = `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
  //         locations,
  //       )}, Path: ${JSON.stringify(path)}`;
  //       postToSlack(errorMessage);
  //     });
  //   }
  //   if (networkError) {
  //     const errorMessage = `[Network error]: ${networkError}`;
  //     postToSlack(errorMessage);
  //   }

  // },
});

const postToSlack = async (message) => {
  try {
    if (process.env.NODE_ENV === 'development') return;
    let currentUser = firebase.auth().currentUser;
    const messageWithUser = `${
      currentUser ? currentUser.email : 'Unknown email'
    } ${message}`;
    const data = { text: messageWithUser };
    fetch(process.env.REACT_APP_SLACK_WEBHOOK, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  } catch (error) {
    console.error(`Error posting to Slack`, error.message);
  }
};

const Provider = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Provider;
