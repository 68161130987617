import React, { createContext, useContext, useState } from 'react';

export const Context = createContext({});

export const Provider = (props) => {
  // Initial values are obtained from the props
  const { children } = props;

  const [visible, setVisible] = useState(false);
  const [selectedItem, _setSelectedItem] = useState(null);
  const [setter, setSetter] = useState(null);
  const [data, setData] = useState(null);
  const [title, setTitle] = useState(null);

  const handleTopLevelFilterClick = (label, data, item, setter) => {
    if (visible) {
      hideFilterList();
    } else {
      showFilterList(label, data, item, setter);
    }
  };

  const showFilterList = (title, data, item, setterFn) => {
    setVisible(true);
    _setSelectedItem(item);
    setSetter(() => setterFn);
    setData(data);
    setTitle(title);
  };

  const hideFilterList = () => {
    setVisible(false);
    _setSelectedItem(null);
    setSetter(null);
    setData(null);
    setTitle(null);
  };

  const setSelectedItem = (item) => {
    _setSelectedItem(item);
    setter(item);
  };

  // Make the context object:
  const filterContext = {
    visible,
    setVisible,
    selectedItem,
    setSelectedItem,
    data,
    setData,
    title,
    setTitle,
    showFilterList,
    hideFilterList,
    handleTopLevelFilterClick,
  };

  // pass the value in provider and return
  return <Context.Provider value={filterContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

export const useFilterContext = () => useContext(Context);
