import React, { createContext, useContext, useState } from 'react';
import { createContextualCan } from '@casl/react';
import { ability } from '../casl/ability';

export const Context = createContext();

export const Provider = (props) => {
  // Initial values are obtained from the props
  const { children } = props;

  // pass the value in provider and return
  return <Context.Provider value={ability}>{children}</Context.Provider>;
};

export const Can = createContextualCan(Context.Consumer);

export const useAbilityContext = () => useContext(Context);
