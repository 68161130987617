import styled from 'styled-components';
import { space } from 'styled-system';

export const StyledContainer = styled.div(space);

export const LayoutWrapper = styled.div`
  background: #fff;
  min-height: 100vh;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  background: #fff;
  min-height: 80vh;
  margin: 54px 8px 54px;
`;
