export const computePrice = (price, discount1, discount2) => {
  if (!discount2) discount2 = 0;
  const baseDiscountApplied = price - percentageValue(price, discount1);
  const actionDiscountApplied =
    baseDiscountApplied - percentageValue(baseDiscountApplied, discount2);

  const rounded = actionDiscountApplied.toFixed(2);
  return rounded;
};

export const percentageValue = (num, per) => {
  return (num / 100) * per;
};

export const getOrderTotal = (items, priceKey = 'price') => {
  if (!items || items.length === 0) return 0;
  return items.reduce((a, b) => a + b[priceKey] * b.quantity, 0);
};
