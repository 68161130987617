import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import ApolloProvider from './components/ApolloProvider';
import ScrollToTop from './components/ScrollToTop';
import { ContextProvider } from './context';
import { ModalProvider, BaseModalBackground } from 'styled-react-modal';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import Layout from './components/Layout';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';
import CacheBuster from './components/CacheBuster';
import moment from 'moment';
import localization from 'moment/locale/bs';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'antd/dist/antd.css';
import './css/App.css';
import OfflineProvider from './components/OfflineProvider';
import ErrorBoundary from './components/ErrorBoundary';

moment.updateLocale(process.env.REACT_APP_LOCALE, localization);

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: opacity ease 200ms;
`;

ReactDOM.render(
  <CacheBuster>
    <ApolloProvider>
      <Helmet>
        <meta name="viewport" content="width=device-width" />
      </Helmet>
      <ModalProvider backgroundComponent={FadingBackground}>
        <ContextProvider>
          <Router basename={process.env.REACT_APP_BASE_PATH}>
            <OfflineProvider>
              <ScrollToTop />
              <Layout>
                <Routes />
              </Layout>
            </OfflineProvider>
          </Router>
        </ContextProvider>
      </ModalProvider>
    </ApolloProvider>
  </CacheBuster>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
