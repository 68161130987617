import React, { useEffect, useState } from 'react';
import { ReactComponent as Logo } from '../../assets/logo/LogoBlue.svg';
import { Redirect, useLocation } from 'react-router-dom';
import { Input, Button, Alert, Form } from 'antd';
import { useAuthContext, useIndexdbContext } from '../../context';
import {
  UserOutlined,
  LockOutlined,
  DisconnectOutlined,
} from '@ant-design/icons';

const BaseSignInForm = () => {
  let location = useLocation();
  const [showOfflineRedirect, setShowOfflineRedirect] = useState(false);
  const [redirectToOffline, setRedirectToOffline] = useState(false);
  const {
    doSignInWithEmailAndPassword,
    redirectToReferrer,
    loading,
    error,
    setError,
  } = useAuthContext();
  const { generalInfoAboutData } = useIndexdbContext();

  useEffect(() => {
    const getDataInfo = async () => {
      const { needSync } = await generalInfoAboutData();
      setShowOfflineRedirect(!needSync);
    };
    getDataInfo();
  }, [generalInfoAboutData]);

  const onFinish = (values) => {
    doSignInWithEmailAndPassword(values.email, values.password);
  };

  const { from } = location.state || { from: { pathname: '/customers' } };
  if (redirectToReferrer) return <Redirect to={from} />;
  if (redirectToOffline) return <Redirect to="/offlinemode" />;

  return (
    <div style={{ height: '100%', minHeight: '100vh' }}>
      <div
        style={{
          width: '100%',
          maxWidth: '800px',
          padding: '0 20px 0',
          paddingTop: '20vh',
        }}
      >
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Logo
            className="hide-logo-on-keyboard"
            style={{ height: '64px', marginBottom: '20px' }}
          />
        </div>
        <Form onFinish={onFinish}>
          {error && (
            <Alert
              message="Pogrešan E-mail ili Password"
              type="error"
              showIcon
              closable
              onClose={() => setError(null)}
            />
          )}
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'E-mail nije validan!',
              },
            ]}
          >
            <Input
              size="large"
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="E-mail"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rule={[
              {
                required: true,
                type: 'password',
                message: 'Molimo unesite password!',
              },
            ]}
          >
            <Input
              type="password"
              size="large"
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item label=" " colon={false}>
            <Button
              loading={loading}
              type="primary"
              size="large"
              htmlType="submit"
              className="login-form-button"
              style={{ width: '100%' }}
            >
              Prijavi se
            </Button>
          </Form.Item>
        </Form>
      </div>
      {showOfflineRedirect && (
        <div style={{ position: 'absolute', bottom: '0px', right: '10px' }}>
          <span onClick={() => setRedirectToOffline(true)}>
            <DisconnectOutlined /> <span>Offline narudžbe</span>
          </span>
        </div>
      )}
    </div>
  );
};

export default BaseSignInForm;
