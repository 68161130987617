import React from 'react';
import Navbar from '../Navigation';
import { LayoutWrapper, ContentWrapper } from './style';
import { useLocation } from 'react-router-dom';

const Layout = ({ children }) => {
  const location = useLocation();
  return (
    <LayoutWrapper>
      {children}
      {location.pathname !== '/reports/salesmans/map' && <Navbar />}
    </LayoutWrapper>
  );
};

export const Content = ({ children }) => {
  return <ContentWrapper>{children}</ContentWrapper>;
};

export * from './style';
export default Layout;
