import React from 'react';
import { Route, Redirect } from 'react-router';
import { useAuthContext } from '../context';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useAuthContext();

  return (
    <Route
      {...rest}
      render={(props) =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/sign-in',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
