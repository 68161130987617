import gql from 'graphql-tag';

export default gql`
  fragment customerLimitedInfo on CustomerLimitedFields {
    id
    name
    code
    address
    totalDebt
    dueDebt
    blocked
    blockWarning
    city
    category
  }
`;
